@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400&display=swap');

body,
html {
  font-family: 'PT Sans', sans-serif;
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

#root {
  font-family: 'PT Sans', sans-serif;
  height: -webkit-fill-available;
}

// SCROLLBAR
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e3e3e3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #374754;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #045091;
}